import React from "react";
import "./BetaPage.css";
import logo from "../assets/SVG-realanalytica-primary-logo-blue.svg";

const BetaPage = () => {
  return (
    <div className="beta-section">
      <div className="beta-header">
        <h1>Beta 1.0</h1>
        <img src={logo} alt="RealAnalytica Logo" className="beta-logo" />
      </div>
      <div className="beta-content">
        <div className="beta-column left-column">
          <div className="beta-box">
            <h2>Welcome</h2>
            <p>
              You've clicked this for a reason. We're looking for just{" "}
              <strong>
                10 Realtors, Brokers, Investors, or interested users
              </strong>{" "}
              to test our product as we prepare to launch in{" "}
              <strong>Rhode Island</strong>. But first, here's a few things
              about us:
            </p>
            <br />
            <p>
              The market is more competitive than ever. With rising prices,
              lower commissions, and smarter clients, you need an edge. By
              leveraging <strong>cutting-edge data analysis techniques</strong>,
              we dive deeper than just city or town designations, providing you
              with insights at the{" "}
              <strong>
                <em>sub-neighborhood</em>
              </strong>{" "}
              level. Our tools make complex data simple and actionable,
              positioning you as the go-to expert in your area.
            </p>
          </div>
          <div className="beta-box">
            <h2>Meet RealAnalytica</h2>
            <p>
              We provide hyper-local, marketable real estate data tailored
              specifically for you and your brand. Imagine having all the stats
              you need, personalized to make you the local market expert. Here's
              what you'll get:
            </p>
            <ul>
              <li>
                <strong>Research Tool:</strong> Gain insights into where you may
                move, or invest. Compare appreciation rates, interest rate
                expectations, recent home sales, and other valuable metrics
                between sub-neighborhoods. Dive deeper than just city or town
                groupings to truly understand your market.
              </li>
              <li>
                <strong>Newsletter:</strong> Stay ahead with our updates on
                sub-neighborhood trends. Realtors and investors can subscribe to
                receive the latest insights, helping you position yourself as
                the true expert in your region.
              </li>
              <li>
                <strong>Custom Marketing One-Pagers:</strong> Create
                professional, data-driven summaries about a specific town or
                sub-neighborhood. These are perfect for listing appointments,
                marketing, and media content—convertible to various formats to
                enhance your brand's outreach.
              </li>
            </ul>
          </div>
        </div>
        <div className="beta-column right-column">
          <div className="beta-box">
            <h2>Beta Testing</h2>
            <p>
              Spots are filling up! One of our Founders will contact you if
              selected.
            </p>
            <p>
              Being part of the Beta program is completely{" "}
              <strong>free!</strong>
            </p>
            <p>Benefits of the beta program include:</p>
            <ul>
              <li>Exclusive early access to features</li>
              <li>Direct feedback channels with the development team</li>
              <li>Long term discounts on the final product</li>
              <li>Leave an impact on the final product</li>
            </ul>
          </div>
          <div className="beta-signup-box">
            <a
              href="https://zzmznqc556d.typeform.com/to/whJwGbz6"
              className="beta-signup-btn"
            >
              Sign Up
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BetaPage;
